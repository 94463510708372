.homePageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 100px;
}

.left {
  max-width: 45%;
  margin-right: 5%;
}

.header {
  font-size: 60px;
  color: white;
  margin: 0px;
  font-weight: 600;
}

.subtitle {
  font-size: 20px;
  color: black;
  margin: 0px;
  font-weight: 400;

  margin: 30px 0px;
}

.colored {
  color: black;
}

.downloadImages {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.imageLeft {
  margin-right: 10px;
}

.download {
  width: 225px;
}

.landingImage {
  height: 700px;
}

.downloadButton {
  position: relative;
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 5px;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.downloadButton:hover {
  background-color: #333;
}

@media screen and (max-width: 1250px) {
  .landingImage {
    height: 500px;
  }
  .download {
    width: 200px;
  }
}
@media screen and (max-width: 1050px) {
  .homePageContainer {
    flex-direction: column;
  }

  .left {
    max-width: 100%;
    margin-right: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subtitle {
    text-align: center;
    max-width: 750px;
  }

  .landingImage {
    display: none;
  }
  .header {
    text-align: center;
  }
}
@media screen and (max-width: 750px) {
  .subtitle {
    text-align: center;
    max-width: 500px;
  }
}
@media screen and (max-width: 575px) {
  .header {
    font-size: 50px;
  }
  .downloadImages {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .download {
    width: 250px;
    margin: 0px;
  }

  .imageLeft {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 475px) {
  .subtitle {
    text-align: center;
    max-width: 400px;
    font-size: 20px;
  }
}
@media screen and (max-width: 400px) {
  .header {
    font-size: 50px;
  }
  .subtitle {
    font-size: 22px;
  }
}
@media screen and (max-width: 350px) {
  .header {
    font-size: 40px;
  }
  .subtitle {
    font-size: 18px;
  }
}
