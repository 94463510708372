.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

.container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.card {
  width: 200px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.34);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  border-bottom: 3px solid #609ea1;
}

.card:hover {
  transform: scale(1.05);
}

.card img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.wrapper {
  padding-bottom: 100px;
  padding-top: 50px;
}
