.row {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.photo {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  margin-top: 10px;
  font-size: 20px;
  margin-top: 12px;
  color: black;
  text-decoration: none;
}

.wrapper {
  padding-top: 50px;
}
