body {
  margin: 0px;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  padding: 0px;
  background-color: white;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}
